export const AUTHENTICATION_METHODS_ENUMS = {
  IDENTITY_PROVIDER: 'identity-provider',
  LINK_ACCOUNT: 'link-account'
}

export const AUTHENTICATION_METHODS = [
  { name: 'Identity provider', value: AUTHENTICATION_METHODS_ENUMS.IDENTITY_PROVIDER },
  { name: 'Link account', value: AUTHENTICATION_METHODS_ENUMS.LINK_ACCOUNT },
]

export const AdditionalHeaderInfoPayload = {
  id: 0,
  portalId: false,
  email: false,
  firstName: false,
  lastName: false,
  rollNumber: false,
  latitude: false,
  longitude: false,
  zone: false,
  ward: false,
}
export const AdditionalHeaderInfoList = [
  { id: 1, name: 'Citizen Portal Id (Azure Id)', key: 'portalId', value: 'cp-citizenportal-id', isSelected: false, desc: 'Sub claim from bearer token' },
  { id: 2, name: 'User Email', key: 'email', value: 'cp-user-email', isSelected: false, desc: 'Email claim from bearer token' },
  { id: 3, name: 'User First Name', key: 'firstName', value: 'cp-user-first-name', isSelected: false, desc: 'Given first name from bearer token' },
  { id: 4, name: 'User Last Name', key: 'lastName', value: 'cp-user-last-name', isSelected: false, desc: 'Last name from bearer token' },
  { id: 5, name: 'Property Roll Number', key: 'rollNumber', value: 'cp-roll-number', isSelected: false, desc: 'Unique property ID from property details' },
  { id: 6, name: 'Property Latitude', key: 'latitude', value: 'cp-property-latitude', isSelected: false, desc: 'Property detail' },
  { id: 7, name: 'Property Longitude', key: 'longitude', value: 'cp-property-longitue', isSelected: false, desc: 'Property detail' },
  { id: 8, name: 'Property Zone', key: 'zone', value: 'cp-property-zone', isSelected: false, desc: 'Property detail' },
  { id: 9, name: 'Property Ward', key: 'ward', value: 'cp-property-ward', isSelected: false, desc: 'Property detail' },
]
