<template>
  <div>
    <section class="py-3 px-5 page-banner">
      <b-row class="mb-3">
        <b-col cols="12">
          <router-link to="/widgets" class="go-back-link"><BIconArrowLeftShort />Back to widgets</router-link>
        </b-col>
      </b-row>
      <b-row class="justify-content-between align-items-center">
        <b-col cols="6" class="col-sm-6">
          <div class="select-editable">
            <b-form-select
              tabindex="0"
              v-if="isMultilingualSupportEnabled"
              id="widget-name-for-language"
              class="form-control"
              ref="languageDropdown"
              text-field="languageName"
              value-field="cultureCode"
              :options="languages"
              :disabled="!this.isEditPage"
              v-model="widgetDetails.culture"
              @change="onChangeLanguage()"
              @input="widgetDetails.culture = $event"
              :class="{
                'is-invalid': !widgetDetails.widgetName && formSubmitted,
              }"
            >
            </b-form-select>
            <div class="floating-input-field">
              <b-form-input
                id="widget-name"
                type="text"
                placeholder=" "
                v-model="widgetDetails.widgetName"
                required
                autocomplete="off"
                :maxlength="widgetNameMaxLimit"
                novalidate
                :class="{
                  'is-invalid': !widgetDetails.widgetName && formSubmitted,
                  'multi-lang-enabled': isMultilingualSupportEnabled,
                }"
              ></b-form-input>
              <label for="widget-name">Enter a widget name…</label>
              <div class="font-14 char-left" :class="{ 'text-danger': remainingChars === 'Limit exceeded' }">
                Maximum character limit is {{ widgetNameMaxLimit }}, remaining: {{ remainingChars }}
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!widgetDetails.widgetName && formSubmitted">Widget name required.</b-form-invalid-feedback>
            </div>
            <div class="selected-culture" :class="{ disabled: !this.isEditPage }">{{ widgetDetails.widgetName }}</div>
          </div>
        </b-col>

        <b-col cols="6" class="col-sm-6 option-bar">
          <b-button type="button" id="preview-widget-btn" v-activeBlur class="transparent ml-3" @click="previewWidget">Preview</b-button>
          <b-button
            type="button"
            id="activate-deactivate-widget-btn"
            v-activeBlur
            v-if="isEditPage"
            :disabled="restrictAddWidgetToMultipleCity || validateWidgetToSelectedTenant"
            class="transparent ml-3 mr-3"
            @click="activateWidgetConfirmModal = true"
            >{{ widgetDetails.isActive ? `Deactivate` : `Activate` }}</b-button
          >
          <b-button
            type="button"
            id="delete-widget-btn"
            v-activeBlur
            :disabled="restrictAddWidgetToMultipleCity || validateWidgetToSelectedTenant"
            v-if="isEditPage"
            class="transparent"
            @click="deleteWidgetConfirmModal = true"
            >Delete</b-button
          >
        </b-col>
      </b-row>
    </section>
    <section class="px-5 mt-4" v-if="restrictAddWidgetToMultipleCity">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_ADD_WIDGET_ERROR }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
    <section class="px-5 mt-4" v-else-if="validateWidgetToSelectedTenant">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.WIDGET_NOT_BELONG_TO_TENANT }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
    <template v-else>
      <section class="px-5">
        <LoaderIcon v-if="isEditPage && widgetDetailsLoading" />
        <b-tabs
          v-else
          pills
          card
          vertical
          class="add-edit-widget-tabs"
          content-class="add-widget-content"
          nav-class="add-widget-tabs pl-0"
          active-nav-item-class="theme-font-bold"
        >
          <template v-for="(tab, tIndex) in tabs">
            <b-tab
              title-link-class="pl-0"
              :title="tab.label"
              :active="activeTab.value == tab.value"
              v-if="tab.display"
              :key="tIndex"
              @click="setActiveTab(null, tab)"
              lazy
            >
              <template #title>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="alert-icon">
                    {{ tab.label }}
                    <BIconExclamationCircleFill scale="0.8" v-if="tab.isVisited && tab.hasErrors" variant="danger" class="ml-1 visible" />
                    <BIconExclamationCircleFill scale="0.8" v-else class="ml-1 invisible" variant="danger" />
                  </div>
                  <div class="arrow-right-icon">
                    <ArrowRight v-if="activeTab.value == tab.value" />
                  </div>
                </div>
              </template>
              <h3 class="font-21 theme-font-medium pb-2">
                {{ activeTab.label }}
              </h3>
              <b-form id="add-edit-widget-from" name="add-edit-widget-from" autocomplete="off" @keyup.tab="pageScroll" novalidate>
                <keep-alive>
                  <component
                    :is="activeTab.value"
                    :widgetDetails="widgetDetails"
                    :formSubmitted="tab.isVisited"
                    :isEditPage="isEditPage"
                    :defaultCulture="getDefaultCulture"
                    @setDisplayTab="setDisplayTab"
                  ></component>
                </keep-alive>
              </b-form>
            </b-tab>
          </template>
        </b-tabs>
      </section>
      <div class="add-edit-widget-actions px-5 d-flex justify-content-between">
        <div class="back">
          <b-button
            v-if="activeTab.value != 'Configuration'"
            type="button"
            id="widget-back-btn"
            v-activeBlur
            variant="outline-secondary"
            @click="setActiveTab('back', null)"
            class="btn-h-58"
            ><ChevronLeftIcon class="mr-2" />Back</b-button
          >
        </div>
        <template v-if="!restrictAddWidgetToMaxAllowed && !restrictLocationToAddressSetUpNotDone">
          <div class="save" v-show="displaySaveBtn">
            <b-button type="button" v-activeBlur :disabled="saveLoadingIcon" @click="onSubmitWidget" variant="primary" class="btn-h-58"
              >Save<b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
          </div>
          <div class="next" v-show="!displaySaveBtn">
            <b-button type="button" v-activeBlur variant="primary" class="btn-h-58" @click="setActiveTab('next', null)"
              >Next <ChevronRightIcon class="ml-1"
            /></b-button>
          </div>
        </template>
      </div>
    </template>
    <ConfirmationModal
      :showModal="activateWidgetConfirmModal"
      :title="`CONFIRM`"
      :message="widgetDetails && widgetDetails.isActive ? `DEACTIVATE_WIDGET` : `ACTIVATE_WIDGET`"
      @onConfirm="activateOrDeactivateWidget"
      @closeConfirmModal="activateWidgetConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="deleteWidgetConfirmModal"
      :title="`CONFIRM`"
      :message="`DELETE_WIDGET`"
      @onConfirm="deleteWidget"
      @closeConfirmModal="deleteWidgetConfirmModal = false"
    />
    <SideBar
      :showSideBar="showWidgetPreviewSideBar"
      :componentName="'WidgetPreviewWrapper'"
      :headerTitle="'Widget preview'"
      :payload="{ ...widgetDetails }"
      :isCloseBtn="true"
      :isResetBtn="false"
      :isSaveBtn="false"
      :width="widgetPreviewSidebarWidth"
      @closeSideBar="showWidgetPreviewSideBar = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import LoaderIcon from '../../../assets/svg/loader.svg'
import ArrowRight from '../../../assets/svg/arrow-right.svg'
import ChevronLeftIcon from '../../../assets/svg/chevron-left.svg'
import ChevronRightIcon from '../../../assets/svg/chevron-right.svg'
import { useValidateFields } from '@/composables/useValidateFields'
import { DISPLAY_MESSAGES, WidgetTypesSettings, ColorRegEx } from '../../../utilities/constants'
import { AUTHENTICATION_METHODS_ENUMS, AdditionalHeaderInfoPayload } from '@/utilities/widget-constants'
import { BIconArrowLeftShort, BIconChevronRight, BIconChevronLeft, BIconExclamationCircleFill } from 'bootstrap-vue'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
import { useAxios } from '@/composables/useAxios'
import { useTranslateLanguage } from '@portalTranslateLanguage'

export default {
  name: 'AddEditWidget',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()
    const { updateLanguage } = useTranslateLanguage()
    const { isLoading: languagesLoading, response: languagesResponse, error: languagesError, callApi: languagesApi } = useAxios()
    const { response: getContentWidgetEmailTemplateRes, error: getContentWidgetEmailTemplateError, callApi: getContentWidgetEmailTemplateApi } = useAxios()
    const { response: getCultureResourcesResponse, callApi: getCultureResourcesApi } = useAxios()

    return {
      validateUrl,
      scrollToErrorMessage,
      languagesLoading,
      languagesResponse,
      languagesError,
      languagesApi,
      getContentWidgetEmailTemplateRes,
      getContentWidgetEmailTemplateError,
      getContentWidgetEmailTemplateApi,
      updateLanguage,
      getCultureResourcesResponse,
      getCultureResourcesApi,
    }
  },
  data() {
    return {
      tabs: [
        {
          label: 'Configuration',
          value: 'Configuration',
          display: true,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Content',
          value: 'Content',
          display: true,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'API',
          value: 'API',
          display: false,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Link accounts',
          value: 'LinkAccounts',
          display: false,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Forms',
          value: 'Forms',
          display: false,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Notifications',
          value: 'Notifications',
          display: true,
          isVisited: false,
          hasErrors: false,
        },
        {
          label: 'Settings',
          value: 'Settings',
          display: false,
          isVisited: false,
          hasErrors: false,
        },
      ],
      activeTab: {
        label: 'Configuration',
        value: 'Configuration',
        display: true,
      },
      showWidgetPreviewSideBar: false,
      displaySaveBtn: false,
      ColorRegEx: ColorRegEx,
      widgetTypesSettings: WidgetTypesSettings,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      isEditPage: false,
      widgetFormCopy: null,
      activateWidgetConfirmModal: false,
      deleteWidgetConfirmModal: false,
      widgetDetails: {
        widgetCategoryID: Number(this.$route.params.widgetCategoryId),
        widgetName: null,
        widgetColor: null,
        widgetLogo: null,
        widgetLogoBlobURI: null,
        culture: '',
        isIconUploaded: false,
        isFixedWidget: false,
        dashboardTxt: '',
        expandedVwTxt: '',
        chartConfigurations: [],
        widgetTblConfigurations: [],
        isDisplayOnSignUpPage: false,
        contentWidgets: {
          emailMessage: '',
          appMessage: '',
          isNotified: false,
          expiryDate: null,
        },
        calendarWidgetSettings: {
          dashboardItemCnt: null,
          expViewItemCnt: null,
          selectedCalendarTypes: [],
          categories: [],
          isNotified: false,
          layout: 'one-column-list',
          headingTxt: null,
          categoryHeadingTxt: null,
          locationHeadingTxt: 'Select Locations',
          isLocationEnabled: false,
          isCategoryDependent: true,
          isOnPublishedDate: true,
          daysPriorToEventDate: null,
        },
        adminNewsWidgetSettings: {
          dashboardItemCnt: null,
          expViewItemCnt: null,
          categories: [],
          isNotified: false,
        },
        locationWidgetSettings: {
          apiUrl: null,
          isFormat: true,
          selectedView: 'form-view',
          keyWidth: null,
          valueWidth: null,
          dashboardItemCnt: null,
          isNotified: false,
          securityMethodId: null,
          contentTokenList: [],
          additionalHeaderInfoFlag: cloneDeep(AdditionalHeaderInfoPayload),
        },
        integrationWidgetSettings: {
          apiUrl: null,
          isFormat: true,
          selectedView: 'form-view',
          keyWidth: null,
          valueWidth: null,
          dashboardItemCnt: null,
          authenticationType: AUTHENTICATION_METHODS_ENUMS.LINK_ACCOUNT,
          linkAccountDetails: null,
          unlinkedWidgetTxt: null,
          linkAccountTxt: null,
          unLinkAccountTxt: null,
          isNotified: false,
          isPropertyDependent: true,
          securityMethodId: null,
          contentTokenList: [],
          additionalHeaderInfoFlag: cloneDeep(AdditionalHeaderInfoPayload),
        },
        formBuilderWidgetSettings: {
          categoryLst: [],
          forms: [],
          statusLst: [],
          actionLinkTxt: null,
          dashboardItemCnt: null,
          isNotified: false,
          isPropertyDependent: true,
          showSearchBar: false,
        },
        engagementWidgetSettings: {
          engagementPlatform: 'bang-the-table',
          isNotified: false,
        },
        myWasteWidgetSettings: {
          platform: null,
          isNotified: false,
        },
        recreationWidgetSettings: {
          recreationPlatform: 'perfect-mind',
          isNotified: false,
          actionLinkTxt: null,
        },
        notificationFrequencySetting: {
          frequency: 'Hourly',
          day: null,
          zoneTimeInHour: null,
        },
      },
      widgetDetailsLoading: false,
      saveLoadingIcon: false,
      widgetPreviewSidebarWidth: '30',
      formSubmitted: false,
      widgetNameMaxLimit: 30,
    }
  },
  components: {
    LoaderIcon,
    ArrowRight,
    ChevronLeftIcon,
    ChevronRightIcon,
    BIconArrowLeftShort,
    BIconChevronRight,
    BIconChevronLeft,
    BIconExclamationCircleFill,
    Configuration: () => import('./Configuration.vue'),
    Content: () => import('./Content.vue'),
    API: () => import('./API.vue'),
    Notifications: () => import('./Notifications.vue'),
    LinkAccounts: () => import('./LinkAccounts.vue'),
    Forms: () => import('./Forms.vue'),
    Settings: () => import('./settings/Settings.vue'),
    ConfirmationModal: () => import('../../common/ConfirmationModal.vue'),
    SideBar: () => import('@/components/common/SideBar.vue'),
  },
  computed: {
    restrictLocationToAddressSetUpNotDone() {
      return this.selectedTenant.length && !this.selectedTenant[0].isAddressSetupDone && this.widgetDetails.widgetCategoryID === 4
    },
    restrictAddWidgetToMultipleCity() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    validateWidgetToSelectedTenant() {
      return (
        this.isEditPage &&
        this.selectedTenant.length === 1 &&
        this.widgetDetails.tenantID > 0 &&
        this.selectedTenant[0].tenantID !== this.widgetDetails.tenantID
      )
    },
    restrictAddWidgetToMaxAllowed() {
      return (
        !this.isEditPage &&
        this.applicableWidgets.some((widget) => widget.widgetCategoryId === this.widgetDetails.widgetCategoryID && widget.existingCnt >= widget.allowedCnt)
      )
    },
    validateIntegrationWidgetLinkAccountContentFields() {
      return (
        this.widgetDetails.widgetCategoryID === 5 &&
        this.widgetDetails.integrationWidgetSettings.authenticationType === AUTHENTICATION_METHODS_ENUMS.LINK_ACCOUNT
      )
    },
    getDefaultCulture() {
      if (this.languages && this.languages.length) {
        const defaultLanguge = this.languages.find((language) => language.isDefault)
        return defaultLanguge ? defaultLanguge.cultureCode : ''
      }
      return ''
    },
    remainingChars() {
      const remaining = this.widgetNameMaxLimit - (this.widgetDetails.widgetName?.length || 0)
      return remaining < 0 ? 'Limit exceeded' : remaining
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      applicableWidgets: (state) => state.widgets.applicableWidgets,
      bangTheTableIsValid: (state) => state.widgets.bangTheTableIsValid,
      perfectMindConfigIsValid: (state) => state.widgets.perfectMindConfigIsValid,
      languages: (state) => state.languages.languages,
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  async created() {
    await this.getLanguages()
    if (this.$route.name === 'Edit-widget') {
      this.isEditPage = true
      this.getWidgetDetails()
    }
    this.getContentWidgetEmailTemplate()
    this.getCultureResources()
  },
  mounted() {
    this.setDisplayTab()
  },

  methods: {
    async getCultureResources() {
      await this.getCultureResourcesApi({ method: 'get', url: tenantSpecificURL(`${ServiceUrls.getCultureResources}/${this.widgetDetails.culture}`) })
      if (this.getCultureResourcesResponse) {
        this.updateLanguage(this.getCultureResourcesResponse.resources)
      }
    },
    async previewWidget() {
      if (this.widgetDetails.widgetCategoryID === 4 || this.widgetDetails.widgetCategoryID === 5 || this.widgetDetails.widgetCategoryID === 8) {
        this.widgetPreviewSidebarWidth = '55'
      } else {
        this.widgetPreviewSidebarWidth = '30'
      }
      this.formSubmitted = true
      this.setVisitedTabs()
      if (this.validateWidgetForm()) {
        await this.formatPayload()
        this.showWidgetPreviewSideBar = true
      } else {
        this.showToaster(false, 'WIDGET_PREVIEW_VALIDATION_ERROR', 'danger')
      }
    },
    pageScroll(event) {
      // tab = 9
      if (event.keyCode === 9) {
        const position = event.target.getBoundingClientRect()
        const padding = 133 // 85 + 48 = 133 action fixed footer height = 85 and input field height 48
        const viewHeight = window.innerHeight - padding
        const scrollPosition = window.top.scrollY
        if (position.top > viewHeight) {
          window.scrollTo(0, scrollPosition + padding)
        }
      }
    },
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    setDisplayTab() {
      this.tabs.forEach((tab) => {
        const selectedWidgetType = this.widgetTypesSettings.find((widget) => widget.widgetCategoryID === this.widgetDetails.widgetCategoryID)
        if (selectedWidgetType) {
          tab.display = selectedWidgetType.tabs.indexOf(tab.value) > -1
          if (tab.value === 'Notifications') {
            if (
              (this.widgetDetails.widgetCategoryID === 4 && !this.widgetDetails.locationWidgetSettings.isFormat) ||
              (this.widgetDetails.widgetCategoryID === 5 && !this.widgetDetails.integrationWidgetSettings.isFormat)
            ) {
              tab.display = false
            }
          }
          if (tab.value === 'LinkAccounts') {
            if (
              this.widgetDetails.widgetCategoryID === 5 &&
              this.widgetDetails.integrationWidgetSettings.authenticationType === AUTHENTICATION_METHODS_ENUMS.LINK_ACCOUNT
            ) {
              tab.display = true
            } else {
              tab.display = false
            }
          }
        }
      })
      this.setPropertyDependent()
    },
    setActiveTab(type, selectedTab) {
      const displayTabs = this.tabs.filter((tab) => tab.display)
      const index = displayTabs.findIndex((tab) => tab.value === this.activeTab.value)
      displayTabs[index].isVisited = true
      if (type) {
        this.activeTab = type === 'back' ? displayTabs[index - 1] : displayTabs[index + 1]
      } else {
        this.activeTab = selectedTab
      }
      const activeIndex = displayTabs.findIndex((tab) => tab.value === this.activeTab.value)
      this.displaySaveBtn = activeIndex + 1 === displayTabs.length
      this.scrollToErrorMessage()
      this.validateWidgetForm()
    },
    onChangeLanguage() {
      this.$refs.languageDropdown.$el.blur()
      this.getWidgetDetails()
      this.getContentWidgetEmailTemplate()
      this.getCultureResources()
    },
    getWidgetDetails() {
      this.widgetDetailsLoading = true
      this.$store
        .dispatch('widgets/getWidgetDetails', {
          widgetID: this.$route.params.widgetID,
          culture: this.widgetDetails.culture,
          multilingualSupport: this.isMultilingualSupportEnabled,
        })
        .then((response) => {
          this.widgetDetailsLoading = false
          if (response.data.locationWidgetSettings) {
            if (!response.data.locationWidgetSettings.additionalHeaderInfoFlag) {
              response.data.locationWidgetSettings.additionalHeaderInfoFlag = cloneDeep(AdditionalHeaderInfoPayload)
            }
            response.data.locationWidgetSettings.applySecurityMethod = !!response.data.locationWidgetSettings.securityMethodId
          }
          if (response.data.integrationWidgetSettings) {
            if (!response.data.integrationWidgetSettings.additionalHeaderInfoFlag) {
              response.data.integrationWidgetSettings.additionalHeaderInfoFlag = cloneDeep(AdditionalHeaderInfoPayload)
            }
            response.data.integrationWidgetSettings.applySecurityMethod = !!response.data.integrationWidgetSettings.securityMethodId
          }
          this.widgetDetails = response.data
          this.setDisplayTab()
        })
        .catch(() => {
          this.widgetDetailsLoading = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        })
    },
    async getContentWidgetEmailTemplate() {
      if (this.widgetDetails.widgetCategoryID === 1) {
        const url = `${ServiceUrls.getContentWidgetEmailTemplate}/${this.widgetDetails.culture}/${this.selectedTenant[0].tenantID}`
        await this.getContentWidgetEmailTemplateApi({ method: 'get', url })
        if (this.getContentWidgetEmailTemplateRes) {
          if (!this.isEditPage) {
            this.widgetDetails.contentWidgets.appMessage = this.getContentWidgetEmailTemplateRes.appMessage
            this.widgetDetails.contentWidgets.emailMessage = this.getContentWidgetEmailTemplateRes.emailMessage
          }
          this.$store.commit('widgets/setContentWidgetEmailTemplate', this.getContentWidgetEmailTemplateRes)
        }
        if (this.getContentWidgetEmailTemplateError) {
          this.$store.commit('widgets/setContentWidgetEmailTemplate', {
            appMessage: '',
            emailMessage: '',
          })
        }
      }
    },
    activateOrDeactivateWidget() {
      const postObj = {
        isActive: !this.widgetDetails.isActive,
        widgetID: this.widgetDetails.widgetID,
      }
      this.$store
        .dispatch('widgets/activateOrDeactivateWidget', postObj)
        .then((response) => {
          this.activateWidgetConfirmModal = false
          if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
            this.showToaster(response.data.errorMessage, false, 'danger')
          } else if (response.data) {
            this.getWidgetDetails()
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: !this.widgetDetails.isActive ? 'ACTIVATE_WIDGET' : 'DEACTIVATE_WIDGET',
              type: 'success',
            })
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger',
            })
          }
        })
        .catch(() => {
          this.activateWidgetConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        })
    },
    deleteWidget() {
      const postObj = {
        userID: 0,
        widgetID: this.widgetDetails.widgetID,
        comments: 'Deleting widget',
      }
      this.$store
        .dispatch('widgets/deleteWidget', postObj)
        .then((response) => {
          this.deleteWidgetConfirmModal = false
          if (response.data) {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'DELETE_WIDGET',
              type: 'success',
            })
            this.$router.push('/widgets').catch(() => {})
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger',
            })
          }
        })
        .catch(() => {
          this.deleteWidgetConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        })
    },
    validateCalendarCategories() {
      return this.widgetDetails.calendarWidgetSettings.categories.filter((category) => category.isSelected === true).length === 0
    },
    validateNewsCategories() {
      return this.widgetDetails.adminNewsWidgetSettings.categories.filter((category) => category.isSelected === true).length === 0
    },
    validateConfiguration() {
      const color = this.widgetDetails.widgetColor ? this.widgetDetails.widgetColor.match(ColorRegEx) !== null : false
      const logo = this.widgetDetails.isIconUploaded
        ? !!(this.widgetDetails.widgetLogo || this.widgetDetails.widgetLogoBlobURI)
        : !!this.widgetDetails.widgetLogoBlobURI
      const bangTheTable = this.widgetDetails.widgetCategoryID === 7 ? this.bangTheTableIsValid : true
      const perfectMind = this.widgetDetails.widgetCategoryID === 9 ? this.perfectMindConfigIsValid : true
      const myWastePlatform = this.widgetDetails.widgetCategoryID === 8 ? !!this.widgetDetails.myWasteWidgetSettings.platform : true
      const result = color && logo && !this.restrictAddWidgetToMaxAllowed && bangTheTable && perfectMind && myWastePlatform
      const index = this.tabs.findIndex((tab) => tab.value === 'Configuration')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateContent() {
      const locationSelectedView =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails.locationWidgetSettings.isFormat
          ? !!this.widgetDetails.locationWidgetSettings.selectedView
          : true
      const integrationSelectedView =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails.integrationWidgetSettings.isFormat
          ? !!this.widgetDetails.integrationWidgetSettings.selectedView
          : true
      const locationKeyWidth =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails.locationWidgetSettings.selectedView === 'table-view'
          ? Number.isInteger(this.widgetDetails.locationWidgetSettings.keyWidth)
            ? !!(Number.isInteger(this.widgetDetails.locationWidgetSettings.keyWidth) && this.widgetDetails.locationWidgetSettings.keyWidth <= 100)
            : false
          : true
      const integrationKeyWidth =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails.integrationWidgetSettings.selectedView === 'table-view'
          ? Number.isInteger(this.widgetDetails.integrationWidgetSettings.keyWidth)
            ? !!(Number.isInteger(this.widgetDetails.integrationWidgetSettings.keyWidth) && this.widgetDetails.integrationWidgetSettings.keyWidth <= 100)
            : false
          : true
      const locationValueWidth =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails.locationWidgetSettings.selectedView === 'table-view'
          ? Number.isInteger(this.widgetDetails.locationWidgetSettings.valueWidth)
            ? !!(Number.isInteger(this.widgetDetails.locationWidgetSettings.valueWidth) && this.widgetDetails.locationWidgetSettings.valueWidth <= 100)
            : false
          : true
      const integrationValueWidth =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails.integrationWidgetSettings.selectedView === 'table-view'
          ? Number.isInteger(this.widgetDetails.integrationWidgetSettings.valueWidth)
            ? !!(Number.isInteger(this.widgetDetails.integrationWidgetSettings.valueWidth) && this.widgetDetails.integrationWidgetSettings.valueWidth <= 100)
            : false
          : true
      const dasContent = this.widgetDetails.widgetCategoryID === 1 ? !!this.widgetDetails.dashboardTxt : true
      const dasExContent = this.widgetDetails.widgetCategoryID === 1 ? !!this.widgetDetails.expandedVwTxt : true
      const calendarType = this.widgetDetails.widgetCategoryID === 2 ? !!this.widgetDetails.calendarWidgetSettings.selectedCalendarTypes.length : true
      const calendarCategories = this.widgetDetails.widgetCategoryID === 2 ? !this.validateCalendarCategories() : true
      const calendarDashboardItems = this.widgetDetails.widgetCategoryID === 2 ? !!this.widgetDetails.calendarWidgetSettings.dashboardItemCnt : true
      const calendarDashboardExpandedViewItems = this.widgetDetails.widgetCategoryID === 2 ? !!this.widgetDetails.calendarWidgetSettings.expViewItemCnt : true
      const newsCategories = this.widgetDetails.widgetCategoryID === 3 ? !this.validateNewsCategories() : true
      const newsDashboardItems = this.widgetDetails.widgetCategoryID === 3 ? !!this.widgetDetails.adminNewsWidgetSettings.dashboardItemCnt : true
      const newsDashboardExpandedViewItems = this.widgetDetails.widgetCategoryID === 3 ? !!this.widgetDetails.adminNewsWidgetSettings.expViewItemCnt : true
      const locationDashboardItems =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails.locationWidgetSettings.isFormat
          ? !!this.widgetDetails.locationWidgetSettings.dashboardItemCnt
          : true
      const integrationDashboardItems =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails.integrationWidgetSettings.isFormat
          ? !!this.widgetDetails.integrationWidgetSettings.dashboardItemCnt
          : true
      const unlinkedWidgetTxt = this.validateIntegrationWidgetLinkAccountContentFields ? !!this.widgetDetails.integrationWidgetSettings.unlinkedWidgetTxt : true
      const linkAccountTxt = this.validateIntegrationWidgetLinkAccountContentFields ? !!this.widgetDetails.integrationWidgetSettings.linkAccountTxt : true
      const unLinkAccountTxt = this.validateIntegrationWidgetLinkAccountContentFields ? !!this.widgetDetails.integrationWidgetSettings.unLinkAccountTxt : true
      const formBuilderActionLinkTxt = this.widgetDetails.widgetCategoryID === 6 ? !!this.widgetDetails.formBuilderWidgetSettings.actionLinkTxt : true
      const formDashboardItemCnt = this.widgetDetails.widgetCategoryID === 6 ? !!this.widgetDetails.formBuilderWidgetSettings.dashboardItemCnt : true
      const recreationActionLinkTxt = this.widgetDetails.widgetCategoryID === 9 ? !!this.widgetDetails.recreationWidgetSettings.actionLinkTxt : true
      const result =
        locationSelectedView &&
        integrationSelectedView &&
        locationKeyWidth &&
        integrationKeyWidth &&
        locationValueWidth &&
        integrationValueWidth &&
        dasContent &&
        dasExContent &&
        calendarType &&
        calendarCategories &&
        calendarDashboardItems &&
        calendarDashboardExpandedViewItems &&
        newsCategories &&
        newsDashboardItems &&
        newsDashboardExpandedViewItems &&
        locationDashboardItems &&
        integrationDashboardItems &&
        unlinkedWidgetTxt &&
        linkAccountTxt &&
        unLinkAccountTxt &&
        formBuilderActionLinkTxt &&
        formDashboardItemCnt &&
        recreationActionLinkTxt
      const index = this.tabs.findIndex((tab) => tab.value === 'Content')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateAPI() {
      const locationApiEndpoint = this.widgetDetails.widgetCategoryID === 4 ? this.validateUrl(this.widgetDetails.locationWidgetSettings.apiUrl) : true
      const locationSecurityMethod =
        this.widgetDetails.widgetCategoryID === 4 && this.widgetDetails?.locationWidgetSettings?.applySecurityMethod
          ? !!this.widgetDetails.locationWidgetSettings.securityMethodId
          : true
      const integrationApiEndpoint = this.widgetDetails.widgetCategoryID === 5 ? this.validateUrl(this.widgetDetails.integrationWidgetSettings.apiUrl) : true
      const integrationSecurityMethod =
        this.widgetDetails.widgetCategoryID === 5 && this.widgetDetails?.integrationWidgetSettings?.applySecurityMethod
          ? !!this.widgetDetails.integrationWidgetSettings.securityMethodId
          : true
      const result = locationApiEndpoint && locationSecurityMethod && integrationApiEndpoint && integrationSecurityMethod
      const index = this.tabs.findIndex((tab) => tab.value === 'API')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateLinkAccounts() {
      let result = true
      if (this.widgetDetails.integrationWidgetSettings.authenticationType === AUTHENTICATION_METHODS_ENUMS.LINK_ACCOUNT) {
        result = !!this.widgetDetails.integrationWidgetSettings.linkAccountDetails
      }
      const index = this.tabs.findIndex((tab) => tab.value === 'LinkAccounts')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateForms() {
      const forms = this.widgetDetails.widgetCategoryID === 6 ? !!this.widgetDetails.formBuilderWidgetSettings.forms.length : true
      const status = this.widgetDetails.widgetCategoryID === 6 ? !!this.widgetDetails.formBuilderWidgetSettings.statusLst.length : true
      const result = forms && status
      const index = this.tabs.findIndex((tab) => tab.value === 'Forms')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateNotifications() {
      const notificationFrequency =
        this.widgetDetails.widgetCategoryID !== 2 || this.widgetDetails.widgetCategoryID !== 8
          ? !!this.widgetDetails.notificationFrequencySetting.frequency
          : true
      const notificationDay =
        (this.widgetDetails.widgetCategoryID !== 2 || this.widgetDetails.widgetCategoryID !== 8) &&
        this.widgetDetails.notificationFrequencySetting.frequency === 'Weekly'
          ? this.widgetDetails.notificationFrequencySetting.day === 0
            ? true
            : !!this.widgetDetails.notificationFrequencySetting.day
          : true
      const notificationTime =
        (this.widgetDetails.widgetCategoryID !== 2 || this.widgetDetails.widgetCategoryID !== 8) &&
        (this.widgetDetails.notificationFrequencySetting.frequency === 'Daily' || this.widgetDetails.notificationFrequencySetting.frequency === 'Weekly')
          ? this.widgetDetails.notificationFrequencySetting.zoneTimeInHour === 0
            ? true
            : !!this.widgetDetails.notificationFrequencySetting.zoneTimeInHour
          : true
      const appMessage =
        this.widgetDetails.widgetCategoryID === 1 && this.widgetDetails.contentWidgets.isNotified ? !!this.widgetDetails.contentWidgets.appMessage : true
      const emailMessage =
        this.widgetDetails.widgetCategoryID === 1 && this.widgetDetails.contentWidgets.isNotified ? !!this.widgetDetails.contentWidgets.emailMessage : true
      const result = appMessage && emailMessage && notificationFrequency && notificationDay && notificationTime
      const index = this.tabs.findIndex((tab) => tab.value === 'Notifications')
      this.tabs[index].hasErrors = !result
      return result
    },
    validateWidgetForm() {
      const widgetName = !!this.widgetDetails.widgetName
      const widgetNameCharLimit = widgetName ? this.remainingChars !== 'Limit exceeded' : false
      const configuration = this.validateConfiguration()
      const content = this.validateContent()
      const API = this.widgetDetails.widgetCategoryID === 4 || this.widgetDetails.widgetCategoryID === 5 ? this.validateAPI() : true
      const linkAccounts = this.widgetDetails.widgetCategoryID === 5 ? this.validateLinkAccounts() : true
      const forms = this.validateForms()
      const notifications = this.validateNotifications()
      const result = widgetName && widgetNameCharLimit && configuration && content && API && linkAccounts && forms && notifications
      this.$store.commit('widgets/setAddWidgetFormValidStatus', result)
      return result
    },
    setVisitedTabs() {
      const displayTabs = this.tabs.filter((tab) => tab.display)
      displayTabs.forEach((tab) => (tab.isVisited = true))
      this.scrollToErrorMessage()
    },
    formatPayload() {
      return new Promise((resolve, reject) => {
        this.widgetFormCopy = cloneDeep(this.widgetDetails)
        this.widgetTypesSettings.forEach((settings) => {
          if (settings.widgetCategoryID !== this.widgetDetails.widgetCategoryID && settings.name !== 'contentWidgets') {
            this.widgetDetails[settings.name] = null
          }
        })
        if (!this.isEditPage) {
          this.widgetDetails.tenantID = this.selectedTenant[0].tenantID
        }
        if (!this.isEditPage && !this.isMultilingualSupportEnabled) {
          this.widgetDetails.culture = 'en-us'
        }
        if (this.widgetDetails.widgetCategoryID === 4) {
          if (!this.widgetDetails.locationWidgetSettings.isFormat) {
            this.widgetDetails.chartConfigurations = []
            this.widgetDetails.widgetTblConfigurations = []
          }
        } else if (this.widgetDetails.widgetCategoryID === 5) {
          if (!this.widgetDetails.integrationWidgetSettings.isFormat) {
            this.widgetDetails.chartConfigurations = []
            this.widgetDetails.widgetTblConfigurations = []
          }
        } else {
          this.widgetDetails.chartConfigurations = []
          this.widgetDetails.widgetTblConfigurations = []
        }

        if (this.widgetDetails.isIconUploaded) {
          this.widgetDetails.widgetLogoBlobURI = this.widgetDetails.widgetLogo ? null : this.widgetDetails.widgetLogoBlobURI
        } else {
          this.widgetDetails.widgetLogo = null
        }
        resolve()
      })
    },
    async onSubmitWidget() {
      this.setVisitedTabs()
      this.formSubmitted = true
      if (this.validateWidgetForm()) {
        this.saveLoadingIcon = true
        const action = this.isEditPage ? 'widgets/updateWidgetDetails' : 'widgets/addWidgetDetails'
        await this.formatPayload()
        this.$store
          .dispatch(action, this.widgetDetails)
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data) {
              this.showToaster(false, this.isEditPage ? 'UPDATE_WIDGET' : 'ADD_WIDGET', 'success')
              this.$router.push('/widgets').catch(() => {})
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
            this.assignProperties()
          })
          .catch(() => {
            this.assignProperties()
            this.saveLoadingIcon = false
            this.showToaster(false, 'FAILED', 'danger')
          })
      } else {
        this.showToaster(false, 'ADD_WIDGET_RESOLVE_ERRORS', 'danger')
      }
    },
    assignProperties() {
      this.widgetDetails = cloneDeep(this.widgetFormCopy)
    },
    setPropertyDependent() {
      if (this.selectedTenant.length && !this.isEditPage) {
        if (this.widgetDetails.widgetCategoryID === 4 && !this.selectedTenant[0].isAddressSetupDone) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'ADDRESS_SETUP_NOT_DONE_ERROR',
            type: 'danger',
          })
        }
        if (this.widgetDetails.widgetCategoryID === 5) {
          this.widgetDetails.integrationWidgetSettings.isPropertyDependent = !!this.selectedTenant[0].isAddressSetupDone
        }
        if (this.widgetDetails.widgetCategoryID === 6) {
          this.widgetDetails.formBuilderWidgetSettings.isPropertyDependent = !!this.selectedTenant[0].isAddressSetupDone
        }
      }
    },
    async getLanguages() {
      if (this.selectedTenant.length) {
        await this.languagesApi({ method: 'get', url: tenantSpecificURL(ServiceUrls.getLanguages) })
        if (this.languagesResponse) {
          this.$store.commit('languages/setLanguages', this.languagesResponse)
          this.widgetDetails.culture = this.getDefaultCulture
        }
        if (this.languagesError) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'LANGUAGES_LIST_ERROR',
            type: 'danger',
          })
          this.totalRows = 0
        }
      }
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.setPropertyDependent()
    },
    widgetDetails: {
      deep: true,
      handler() {
        this.validateWidgetForm()
      },
    },
    bangTheTableIsValid() {
      this.validateWidgetForm()
    },
    perfectMindConfigIsValid() {
      this.validateWidgetForm()
    },
    'widgetDetails.widgetCategoryID'() {
      this.getContentWidgetEmailTemplate()
    },
  },
}
</script>
<style lang="scss" scoped>
.char-left {
  position: absolute;
  right: 0;
}
::v-deep .add-widget-content {
  .tab-pane {
    padding: 3.5rem 1.75rem !important;
  }
}
::v-deep .add-widget-tabs {
  border-right: 1px solid #d6d6d6;
  background-color: #fff;
  padding-top: 1.75rem;
  padding-right: 2.5rem;
  a {
    font-size: 18px;
    color: #000000;
  }
  .arrow-right-icon {
    position: absolute;
    right: 25px;
  }
}
.add-edit-widget-actions {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 85px;
  background-color: #fff;
  align-items: center;
  z-index: 1001;
  box-shadow: 0 0 20px #00000012;
}
.option-bar {
  display: flex;
  justify-content: flex-end;
}
.select-editable {
  position: relative;
  .selected-culture {
    display: none;
  }
  .selected-culture.disabled {
    background: #e9ecef;
  }
  #widget-name-for-language {
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
    text-align-last: right;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:focus {
      width: 100%;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      ~ .selected-culture {
        position: absolute;
        bottom: 2px;
        padding-left: 0.75rem;
        display: flex;
        align-items: center;
      }
    }
  }
  .floating-input-field {
    position: initial;
    label {
      display: inline;
      width: auto;
    }
    input.form-control.is-invalid.multi-lang-enabled {
      background-image: none;
    }
  }
}
</style>
